.shepherd-header {
  background-color: transparent !important;
}

.shepherd-element {
  border: 3px solid black !important;
  padding: 14px !important;
}

.shepherd-button {
  font-family: inherit;
}

.show-skip {
  display: block;
}

.hide-skip {
  display: none;
}